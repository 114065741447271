import { AxiosRequestConfig } from 'axios';
import {
  client, NumberedBoolean, SimpleUserType, UserRoles,
} from '../../utils';
import { DocumentType } from '../../utils/constants/documentType';

type UploadFileToDraftApi = (p: {
  draftId: number;
  file: File;
  documentType: DocumentType;
}, config?: AxiosRequestConfig) => Promise<UploadedFileResponse>;

export type UploadedFileResponse = {
  id: number,
  title: string,
  size: number,
  word_count: number,
  custom_properties: { documentType: DocumentType },
};
export const uploadFileToDraftApi: UploadFileToDraftApi = ({ draftId, file, documentType }, configs) => {
  const url: string = `/draft/${draftId}/add-file`;

  const formData: FormData = new FormData();
  formData.append('file', file, file.name);
  formData.append('document_type', documentType);

  return client.post(url, formData, configs)
    .then(({ data }) => data.result);
};

type DeleteFileFromDraftApi = (p: {
  draftId: number;
  fileId: number;
}) => Promise<void>;
export const deleteFileFromDraftApi: DeleteFileFromDraftApi = ({ draftId, fileId }) => {
  const url: string = `/draft/${draftId}/delete-file/${fileId}`;

  return client.delete(url)
    .then(data => data.data.result);
};

export type ProjectQuantityLog = {
  id: number,
  quantity: number,
  is_original: NumberedBoolean,
  user: SimpleUserType & { type: UserRoles }
};
export const getProjectQuantityLog = (projectID: number): Promise<ProjectQuantityLog[]> => {
  const url = `project/${projectID}/get-quantity-log`;

  return client.get(url)
    .then(({ data: { result } }) => result);
};

export enum ProjectQuantityRequestEnum {
  NEW = 1,
  ACCEPT = 2,
  DECLINE = 3,
}

export const isNewProjectQuantityRequest = (
  status: ProjectQuantityRequestEnum,
): status is ProjectQuantityRequestEnum.NEW => (
  status === ProjectQuantityRequestEnum.NEW
);
export const isAcceptProjectQuantityRequest = (
  status: ProjectQuantityRequestEnum,
): status is ProjectQuantityRequestEnum.ACCEPT => (
  status === ProjectQuantityRequestEnum.ACCEPT
);
export const isDeclineProjectQuantityRequest = (
  status: ProjectQuantityRequestEnum,
): status is ProjectQuantityRequestEnum.DECLINE => (
  status === ProjectQuantityRequestEnum.DECLINE
);

export type QuantityRequest = {
  id: number,
  project_id: number,
  quantity: number,
  reason: string,
  state_id: ProjectQuantityRequestEnum,
  user_id: number,
};
export const requestNewQuantityApi = ({
  quantity,
  reason,
  projectID,
}: { quantity: number, reason: string, projectID: number }): Promise<QuantityRequest> => {
  const url = `project/${projectID}/request-new-quantity`;
  const requestData = { quantity, reason };

  return client.post(url, requestData)
    .then(({ data: { result } }) => result);
};

export const changeRequestNewQuantityStatusApi = ({
  projectID, stateID,
}: { projectID: number, stateID: ProjectQuantityRequestEnum }) => {
  const url = `project/${projectID}/change-actual-quantity-request-state`;

  return client.post(url, { state_id: stateID });
};

export const acceptRequestNewQuantityApi = ({
  projectID,
}: { projectID: number }) => (
  changeRequestNewQuantityStatusApi({ projectID, stateID: ProjectQuantityRequestEnum.ACCEPT })
    .then(({ data: { result } }) => result)
);

export const declineRequestNewQuantityApi = ({
  projectID,
}: { projectID: number }) => (
  changeRequestNewQuantityStatusApi({ projectID, stateID: ProjectQuantityRequestEnum.DECLINE })
    .then(({ data: { result } }) => result)
);
