import { TileItemProps } from '../../../../../../../core/components/TileItem';
import { DropdownType, ListItemWithTitle, OrNull } from '../../../../../../../../types';
import { SimpleUserType } from '../../../../../../../../utils';
import { BudgetPeriod } from '../../../../../../../../actions/api-requests-only';

export type BudgetPeriodInDropdown = DropdownType<string> & {
  value: BudgetPeriod,
  isSelected?: boolean,
};

export type BudgetCardItem = TileItemProps | null;

export enum FinancialTypeEnum {
  PROJECT = 'PROJECT',
  CORRECTION = 'CORRECTION',
  PLATFORM_SUBSCRIPTION = 'PLATFORM_SUBSCRIPTION',
  TOOL = 'TOOL',
}

type MutualProjectType = {
  id: number;
  name: string;
  code: string;
  freelancer: OrNull<SimpleUserType>,
  user: OrNull<SimpleUserType>,
  projectType: OrNull<DropdownType>, // BASE on service_id
  contentType: OrNull<DropdownType>,
  department: OrNull<DropdownType>,
  quantity: number,
  billingType: OrNull<ListItemWithTitle>, // price_plan_type
  totalSpend: number, // final_total_price
  financialStatus: OrNull<string>, // TODO check
  deadline: string,
};

export type ProjectToShowInBudget = MutualProjectType & {
  financialType: FinancialTypeEnum.PROJECT;
};

export type CorrectionProjectBudget = MutualProjectType & {
  financialType: FinancialTypeEnum.CORRECTION;
};

export type PlatformSubscriptionType = Pick<
MutualProjectType,
'id' | 'billingType' | 'financialStatus' | 'totalSpend'
> & {
  financialType: FinancialTypeEnum.PLATFORM_SUBSCRIPTION,
  // TODO WARN: Department is missed
};

export type BudgetFinancialItemType = ProjectToShowInBudget
| CorrectionProjectBudget
| PlatformSubscriptionType;

export const isMainProjectFinancialItem = (
  financialItem: BudgetFinancialItemType,
): financialItem is ProjectToShowInBudget => (
  financialItem.financialType === FinancialTypeEnum.PROJECT
);
export const isCorrectionFinancialItem = (
  financialItem: BudgetFinancialItemType,
): financialItem is CorrectionProjectBudget => (
  financialItem.financialType === FinancialTypeEnum.CORRECTION
);
export const isPlatformSubscriptionFinancialItem = (
  financialItem: BudgetFinancialItemType,
): financialItem is PlatformSubscriptionType => (
  financialItem.financialType === FinancialTypeEnum.PLATFORM_SUBSCRIPTION
);

export type BudgetTabProps = {};
