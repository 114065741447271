import { client, DEFAULT_PER_PAGE, SimpleUserType } from '../../utils';
import { DropdownType, ListItemWithTitle, OrNull } from '../../types';
import {
  FinancialTypeEnum,
} from '../../components/profile/components/UserProfile/ClientProfile/ClientProfileContent/FinancialsTabNew/BudgetTab/interfaces';

type ProjectFieldType = {
  id: number;
  name: string;
  code: string;
  external_freelancer: OrNull<string>;
  freelancer_user: OrNull<SimpleUserType>;
  service: OrNull<DropdownType>;
  content_type: OrNull<DropdownType>;
  price_plan_type: OrNull<ListItemWithTitle>;
  project_department: OrNull<DropdownType>; // used to show `department_name`
  owners: SimpleUserType[];
};

type FinancialItem = {
  id: number;
  final_quantity: number;
  final_total_price: number;
  final_state: string;
  final_complete_date: string;
};

type FinancialMainProjectType = FinancialItem & {
  project: ProjectFieldType;
  correction_project: null;
  client_platform_subscription: null;
};
type FinancialCorrectionType = FinancialItem & {
  project: null;
  client_platform_subscription: null;
  correction_project: ProjectFieldType;
};
type FinancialPlatformSubscription = FinancialItem & {
  project: null;
  correction_project: null;
  client_platform_subscription: {
    price_plan_type: OrNull<ListItemWithTitle>;
  };
};
export type FinancialProjectType = FinancialMainProjectType | FinancialCorrectionType | FinancialPlatformSubscription;

export const isMainProjectFinancialType = (
  financialItem: FinancialProjectType,
): financialItem is FinancialMainProjectType => !!financialItem.project;

export const isCorrectionFinancialType = (
  financialItem: FinancialProjectType,
): financialItem is FinancialCorrectionType => !!financialItem.correction_project;

export const isPlatformSubscriptionFinancialType = (
  financialItem: FinancialProjectType,
): financialItem is FinancialPlatformSubscription => !!financialItem.client_platform_subscription;

const financialItemFieldInBudgetFilter = {
  [FinancialTypeEnum.PROJECT]: 'project',
  [FinancialTypeEnum.PLATFORM_SUBSCRIPTION]: 'platformSubscription',
  [FinancialTypeEnum.CORRECTION]: 'corrections',
  [FinancialTypeEnum.TOOL]: 'clientTool',
};
export const getClientBudgetProjects = ({
  clientID,
  periods,
  page = 1,
  resultsPerPage = DEFAULT_PER_PAGE,
  sortType = 'desc',
  orderBy = 'created_at',
}): Promise<{
  data: FinancialProjectType[];
  total: number;
  current_page: number;
  last_page: number;
  per_page: number;
}> => {
  const url = `/client-financial/${clientID}/list`;

  // select only PROJECT, CORRECTION according to SP-4444, SP-4484
  const financialType = [
    financialItemFieldInBudgetFilter[FinancialTypeEnum.PROJECT],
    financialItemFieldInBudgetFilter[FinancialTypeEnum.CORRECTION],
  ].join(',');

  const params = {
    period: periods.join(','),
    page,
    resultsPerPage,
    sort: sortType,
    orderBy,
    financialType,
  };

  return client.get(url, { params })
    .then(({ data: { result } }) => result);
};
