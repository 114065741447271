import moment, { MomentInput } from 'moment';

export type DateRange = {
  from: string;
  to: string;
};

// ignore time, compare days only
export const isDayInRange = (day: MomentInput, { from, to }: DateRange): boolean => (
  moment(day).isBetween(moment(from), moment(to), 'day', '[]')
);

// ignore time, compare days only
export const isDayBefore = (d1: MomentInput, d2: MomentInput): boolean => (
  moment(d1).isBefore(d2, 'day')
);
