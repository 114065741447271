import { isNotEmpty } from './utils';

export const formatWithConfiguration = ({
  price,
  withoutTrailingZero = false,
  maximumDigitsCount,
  minimumDigitsCount,
  symbol = '',
}: {
  price: number,
  withoutTrailingZero?: boolean,
  maximumDigitsCount?: number,
  minimumDigitsCount?: number,
  symbol?: string,
}) => {
  const formattedPrice = new Intl.NumberFormat('de-DE', {
    ...(isNotEmpty(minimumDigitsCount) ? { minimumFractionDigits: minimumDigitsCount } : {}),
    ...(isNotEmpty(maximumDigitsCount) ? { maximumFractionDigits: maximumDigitsCount } : {}),
    ...(withoutTrailingZero ? { trailingZeroDisplay: 'stripIfInteger' } : {}),
  }).format(Math.abs(price) || 0);

  return [
    // add minus prefix, if needed
    price < 0 ? '-' : '',
    // add symbol prefix, if needed
    symbol ? `${symbol}` : '',
    // add formatted price
    `${formattedPrice}`,
  ]
    .filter(Boolean) // remove empty items
    .join(' '); // join all items with space
};
