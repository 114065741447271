import { MouseEvent } from 'react';
import useModal from './useModal';
import { copyText, SEARCH_CALLBACK_DELAY } from '../utils';

const useCopyText = (text: string, timeout = SEARCH_CALLBACK_DELAY) => {
  const {
    isOpen: isCopiedVisible,
    handleOpen: showTextCopied,
    handleClose: hideTextCopied,
  } = useModal();

  const handleCopyClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    copyText(text).then(() => {
      showTextCopied();
      setTimeout(hideTextCopied, timeout);
    });
  };

  return {
    isCopiedVisible,
    handleCopyClick,
  };
};

export default useCopyText;
