import { mixpanelEvents, WritingProjectToolsID } from '../constants';
import { DESIGN_ID } from '../../constants';
import { OrNull } from '../../types';

type ProjectTypesObjectType = {
  isDesignProject?: boolean,
  isGooglePresentation?: boolean,
  isDownloadable?: boolean,
  isFilesWorkflow?: boolean,
  isCopyProject?: boolean,
  isGoogleDocs?: boolean,
  isWord?: boolean,
  isCrowdin?: boolean,
  isPowerPoint?: boolean,
  isGoogleSlides?: boolean,
};
type GetCompletedProjectTrackKeyType = (params: ProjectTypesObjectType) => string;
export const getCompletedProjectTrackKey: GetCompletedProjectTrackKeyType = ({
  isGooglePresentation,
  isDownloadable,
  isFilesWorkflow,
  isGoogleDocs,
  isWord,
  isPowerPoint,
  isGoogleSlides,
}) => {
  if (isGooglePresentation) {
    return mixpanelEvents.PROJECT_MANAGEMENT.FREELANCER_ACTIONS.COMPLETED_PROJECT.DESIGN.PRESENTATION;
  } else if (isDownloadable) {
    return mixpanelEvents.PROJECT_MANAGEMENT.FREELANCER_ACTIONS.COMPLETED_PROJECT.DESIGN.VISUAL_WORKFLOW;
  } else if (isFilesWorkflow) {
    // else FILES_FLOW
    return mixpanelEvents.PROJECT_MANAGEMENT.FREELANCER_ACTIONS.COMPLETED_PROJECT.DESIGN.REPURPOSED_COPY_WORKFLOW;
  } else if (isGoogleDocs) {
    return mixpanelEvents.PROJECT_MANAGEMENT.FREELANCER_ACTIONS.COMPLETED_PROJECT.COPY.GOOGLE_DOC_WORKFLOW;
  } else if (isWord) {
    return mixpanelEvents.PROJECT_MANAGEMENT.FREELANCER_ACTIONS.COMPLETED_PROJECT.COPY.REGULAR_WORKFLOW;
  } else if (isPowerPoint) {
    return mixpanelEvents.PROJECT_MANAGEMENT.FREELANCER_ACTIONS.COMPLETED_PROJECT.COPY.POWER_POINT_WORKFLOW;
  } else if (isGoogleSlides) {
    return mixpanelEvents.PROJECT_MANAGEMENT.FREELANCER_ACTIONS.COMPLETED_PROJECT.COPY.GOOGLE_SLIDES_WORKFLOW;
  }

  // if no one matched;
  return '';
};

type ProjectParamType = {
  service_id: OrNull<number>,
  design_tool_type_id: OrNull<number>,
  project_tool_id: OrNull<number>,
};
type DesignToolsType = { [key: string]: number };
type GetProjectTypesObjectType = (params: { project: ProjectParamType, designToolTypes?: DesignToolsType }) => ProjectTypesObjectType;
export const getProjectTypesObject: GetProjectTypesObjectType = ({ project, designToolTypes = null }) => {
  const result: ProjectTypesObjectType = {};
  if (project) {
    const { service_id: serviceId } = project;
    result.isDesignProject = serviceId === DESIGN_ID;
    if (result.isDesignProject) {
      const { design_tool_type_id: designToolTypeId } = project;
      if (designToolTypes) {
        result.isGooglePresentation = designToolTypeId === designToolTypes.GOOGLE_PRESENTATION;
        result.isDownloadable = designToolTypeId === designToolTypes.DOWNLOADABLE_DESIGN;
        result.isFilesWorkflow = designToolTypeId === designToolTypes.FILES_FLOW;
      }
    } else {
      result.isCopyProject = true;
      const { project_tool_id: projectToolId } = project;
      result.isGoogleDocs = WritingProjectToolsID.GOOGLE_DOCS === projectToolId;
      result.isWord = WritingProjectToolsID.MICROSOFT_WORD === projectToolId;
      result.isCrowdin = WritingProjectToolsID.CROWDIN === projectToolId;
      result.isPowerPoint = WritingProjectToolsID.POWERPOINT === projectToolId;
      result.isGoogleSlides = WritingProjectToolsID.GOOGLE_SLIDES === projectToolId;
    }
  }
  return result;
};
