const mixpanelEvents = {
  PROJECT_CREATION: {
    CREATE_PROJECT: {
      NEW_PROJECT_BUTTON_CLICK_HEADER: 'New project button click header',
      NEW_PROJECT_BUTTON_CLICK_PROJECT_DASHBOARD: 'New project button click project dashboard',
      SELECT_SERVICE: 'Select service',
      SELECT_TRANSLATION_METHOD: 'Select translation method',
      CONTINUE_DRAFT: 'Continue draft',
    },
    INPUT_BRIEFING: {
      ENTER_PROJECT_NAME: 'enter project name',
      SELECT_CONTENT_TYPE: 'select content type',
      SELECT_PROGRAM: 'select program',
      SELECT_LANGUAGE: 'select language',
      SELECT_SOURCE_LANGUAGE: 'select source language(s)',
      PURPOSE_INPUT: 'purpose input',
      TARGET_AUDIENCE_INPUT: 'target audience input',
      DETAILED_OUTLINE_INPUT: 'detailed outline input',
      EXTRA_INFORMATION_INPUT: 'extra information input',
      CONTEXTUAL_INFORMATION_INPUT: 'contextual information input',
      ADDITIONAL_BRIEFING_OPTION_SELECT: 'additional briefing option select',
      SEO_KEYWORD_INPUT: 'SEO keyword input',
      SEO_OPTIMISATION_SELECT: 'Seo optimisation select',
      FIRST_DEADLINE_SELECT_CLICK: 'first deadline select click',
      FINAL_DEADLINE_SELECT_CLICK: 'final deadline select click',
    },
    CHOOSE_FREELANCER: {
      SELECT_FREELANCER_CLICK: 'select freelancer click',
    },
    FINALIZE_BRIEFING: {
      EXIT_n_SAVE_DRAFT: 'Exit & save draft',
      EXIT_FLOW: 'Exit flow',
      CREATE_PROJECT: 'Create project',
      DELETE_DRAFT: 'Delete draft',
    },
    NEXT_STEP_CLICK: 'next step click',
    PREVIOUS_STEP_CLICK: 'previous step click',
  },
  PROJECT_MANAGEMENT: {
    FREELANCER_ACTIONS: {
      CLICK_ACCEPT_PROJECT: 'click accept project',
      CLICK_DECLINE_PROJECT: 'click decline project',
      CLICK_TO_UPLOAD_DRAFT: {
        COPY: {
          REGULAR_WORKFLOW: 'click to upload draft on copy flow - regular workflow',
        },
        DESIGN: {
          VISUAL_WORKFLOW: 'click to upload design on design flow - visual workflow',
          REPURPOSED_COPY_WORKFLOW: 'click to upload draft on design flow - repurposed copy workflow',
        },
      },
      CLICK_TO_OPEN_GOOGLE_DOCS_LINK: 'click to open google docs link',
      UPLOADED_DRAFT: {
        COPY: {
          REGULAR_WORKFLOW: 'uploaded draft Copy flow - regular workflow',
        },
        DESIGN: {
          VISUAL_WORKFLOW: 'uploaded draft Design flow - visual workflow',
          REPURPOSED_COPY_WORKFLOW: 'uploaded draft Design flow - repurposed copy workflow',
        },
      },
      CLICKED_READY_FOR_FEEDBACK: {
        COPY: {
          GOOGLE_DOC_WORKFLOW: 'clicked ready for feedback on copy flow - google doc workflow',
        },
        DESIGN: {
          PRESENTATION: 'clicked ready for feedback on design flow - presentation',
          VISUAL_WORKFLOW: 'clicked ready for feedback on design flow - visual workflow',
        },
      },
      CLICKED_COMPLETE_PROJECT_ON_PD: 'clicked complete project on PD',
      CLICKED_COMPLETE_PROJECT_ON_PROJECT_PAGE: 'clicked complete project on project page',
      COMPLETED_PROJECT: {
        COPY: {
          REGULAR_WORKFLOW: 'completed project - copy flow - regular workflow',
          GOOGLE_DOC_WORKFLOW: 'completed project - copy flow - google doc workflow',
          POWER_POINT_WORKFLOW: 'completed project - copy flow - PowerPoint workflow',
          GOOGLE_SLIDES_WORKFLOW: 'completed project - copy flow - google slides workflow',
        },
        DESIGN: {
          VISUAL_WORKFLOW: 'completed project - Design flow - visual workflow',
          REPURPOSED_COPY_WORKFLOW: 'completed project - Design flow - repurposed copy workflow',
          PRESENTATION: 'completed project - Design flow - presentation',
        },
      },
    },
    CLIENT_ACTIONS: {
      UPLOADED_FEEDBACK: {
        COPY: {
          REGULAR_WORKFLOW: 'uploaded feedback (Copy flow) - regular workflow',
          GOOGLE_DOC_WORKFLOW: 'delivered feedback (Copy flow) - google doc workflow',
        },
        DESIGN: {
          REPURPOSED_COPY_WORKFLOW: 'uploaded feedback (design flow) - repurposed copy workflow',
          VISUAL_WORKFLOW: 'delivered feedback (design flow) - visual workflow',
          PRESENTATION: 'delivered feedback (design flow) - presentation',
        },
      },
      CLICKED_APPROVE_PROJECT_FINISH: 'Clicked approve project/finish',
    },
  },
};

export default mixpanelEvents;
