import { SVGType } from '../../types';
import MicrosoftIconSVG from '../../assets/svg/microsoft-word.svg';
import GoogleIconSVG from '../../assets/svg/google-docs.svg';
import ContentooTranslationToolIconSVG from '../../assets/svg/contetoo_translation_tool.svg';
import PowerPointIconSVG from '../../assets/svg/powerpoint.svg';
import GoogleSlidesIconSVG from '../../assets/svg/google-slides.svg';

export enum WritingProjectTools {
  MICROSOFT_WORD = 'MICROSOFT_WORD',
  GOOGLE_DOCS = 'GOOGLE_DOCS',
  CROWDIN = 'CROWDIN',
  POWERPOINT = 'POWERPOINT',
  GOOGLE_SLIDES = 'GOOGLE_SLIDES',
}

export const WritingProjectToolsID: { [key in WritingProjectTools]: number } = {
  [WritingProjectTools.MICROSOFT_WORD]: 1,
  [WritingProjectTools.GOOGLE_DOCS]: 2,
  [WritingProjectTools.CROWDIN]: 3,
  [WritingProjectTools.POWERPOINT]: 4,
  [WritingProjectTools.GOOGLE_SLIDES]: 5,
};

export const getWritingProjectToolByID = (toolID: number): WritingProjectTools => {
  const WRITING_PROJECT_TOOLS_BY_ID: { [key: number]: WritingProjectTools } = Object.fromEntries(
    Object.entries(WritingProjectToolsID)
      .map(([tool, id]) => [id, tool as WritingProjectTools]),
  );

  return WRITING_PROJECT_TOOLS_BY_ID[toolID];
};

type GoogleServiceTools = WritingProjectTools.GOOGLE_DOCS | WritingProjectTools.GOOGLE_SLIDES;
export const isGoogleServiceTool = (tool: WritingProjectTools): tool is GoogleServiceTools => (
  [
    WritingProjectTools.GOOGLE_DOCS,
    WritingProjectTools.GOOGLE_SLIDES,
  ].includes(tool)
);

export const isGoogleServiceToolID = (toolID: number): boolean => (
  isGoogleServiceTool(getWritingProjectToolByID(toolID))
);

export const getTranslationKeyProjectTool = (tool: WritingProjectTools): string => {
  const TRANSLATION_KEY_PROJECT_TOOLS: { [key in WritingProjectTools]: string } = {
    [WritingProjectTools.MICROSOFT_WORD]: 'microsoft_word',
    [WritingProjectTools.GOOGLE_DOCS]: 'google_docs',
    [WritingProjectTools.CROWDIN]: 'contentoo_localisation_tool',
    [WritingProjectTools.POWERPOINT]: 'PowerPoint',
    [WritingProjectTools.GOOGLE_SLIDES]: 'Google Slides',
  };

  return (
    TRANSLATION_KEY_PROJECT_TOOLS[tool] ?? ''
  );
};

export const getTranslationKeyProjectToolsByID = (toolID: number): string => (
  getTranslationKeyProjectTool(getWritingProjectToolByID(toolID))
);

export const getWritingToolIcon = (tool: WritingProjectTools): SVGType | null => {
  const writingToolIcon: { [toolID in WritingProjectTools]: SVGType } = {
    [WritingProjectTools.MICROSOFT_WORD]: MicrosoftIconSVG,
    [WritingProjectTools.GOOGLE_DOCS]: GoogleIconSVG,
    [WritingProjectTools.CROWDIN]: ContentooTranslationToolIconSVG,
    [WritingProjectTools.POWERPOINT]: PowerPointIconSVG,
    [WritingProjectTools.GOOGLE_SLIDES]: GoogleSlidesIconSVG,
  };

  return writingToolIcon[tool] ?? null;
};

export const getWritingToolIconByID = (toolID: number): SVGType | null => (
  getWritingToolIcon(getWritingProjectToolByID(toolID))
);
