export {
  PROJECT_ATTACHMENTS,
} from './projectAttachments';

export {
  PROJECT_STATUS,
  PROJECT_STATUS_ID,
  PROJECT_STATUS_BY_ID,
} from './projectStatus';

export {
  POSITIONS,
} from './stylesConstants';

export {
  default as industriesIcons,
} from './industriesIcons';

export {
  devUsersIds,
} from './devUsersIds';

export * from './writingProjectTools';

export {
  featureFlags,
  featureFlagValue,
} from './featureFlags';

export {
  PARTICIPANT_ROLES,
} from './participantRoles';

export {
  FREELANCER_ROLES,
} from './freelancerRoles';

export {
  FREELANCER_TYPE_TO_LOCALSTORAGE,
} from './freelancerTypeToLocalStorage';

export {
  SERVICE_KEYS,
  SEO_OPTIMIZES_TYPE,
  SEO_OPTIMIZES_ID,
  SEO_OPTIMIZES_TITLE,
} from './serviceIds';

export {
  default as REG_EXP,
} from './regExp';

export {
  ACTIVE_PROJECT_PAGINATION_KEY,
  CONCEPT_BRIEFINGS_PAGINATION_KEY,
  FINISHED_PROJECT_PAGINATION_KEY,
  ACTIVE_PROJECT_SORTING_KEY,
  FINISHED_PROJECT_SORTING_KEY,
  PROJECT_SORTING_IDS,
} from './projectsDashboard';

export * from './backendConstantsKeys';
export * from './configParams';
export { default as mixpanelEvents } from './mixpanelEvents';

export const MIXPANEL_PROJECT_TOKEN = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN;

export const emptyChangedQuillValue = '<p><br></p>';

export * from './documentType';
export * from './servicesId';
export * from './project';

export * from './recaptcha';
export * from './referrals';

export * from './urls';
