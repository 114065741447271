import { client } from '../../utils';

export type BudgetPeriod = {
  from: string,
  until: string,
};

type RetainerPeriodsResponse = {
  retainer_periods: BudgetPeriod[],
  contract: {
    starting_date: string,
    end_data: string,
  }
};
export const getRetainerPeriods = (clientID: number): Promise<RetainerPeriodsResponse> => {
  const url = `/client-financial/${clientID}/retainer-periods`;
  return client.get(url)
    .then(({ data }) => data.result);
};

export const getTotalsRetainer = ({ clientID, from, until }: { clientID: number, from: string, until: string }) => {
  const url = `/client-financial/${clientID}/totals-retainer`;
  const params = { from, until };
  return client.get(url, { params })
    .then(({ data }) => data.result);
};
