import * as types from '../constants';
import { getProjectUnreadMessagesCount, PROJECT_STATUS } from '../utils';

const initialState = {
  designProject: {},
  isAccepted: false,
  isFirstDesignDraftDeleted: false,
  messages: [],
  unreadMessages: 0,
};

export default function designDetails(state = initialState, action) {
  switch (action.type) {
    case types.GET_DESIGN_PROJECT_BY_ID_SUCCESS:
    case types.UPDATE_DESIGN_PROJECT_SUCCESS:
      return {
        ...state,
        designProject: action.payload,
        unreadMessages: getProjectUnreadMessagesCount({
          project: action.payload,
          userID: action.userID,
        }),
      };
    case types.SAVE_DESIGN_PROJECT: {
      return {
        ...state,
        designProject: action.payload,
      };
    }
    case types.ACCEPT_DESIGN_PROJECT_SUCCESS:
      return {
        ...state,
        isAccepted: true,
        isFeedbackReady: true,
      };
    case types.UNACCEPT_DESIGN_PROJECT:
      return {
        ...state,
        isAccepted: false,
      };
    case types.TRANSFER_TO_DESIGNER_SUCCESS:
      return {
        ...state,
        designProject: {
          ...state.designProject,
          designer: action.payload,
        },
      };
    case types.UPLOAD_DESIGN_DRAFT_SUCCESS: {
      return {
        ...state,
        isFirstDesignDraftDeleted: false,
        isAccepted: false,
        designProject: action.payload,
      };
    }
    case types.DELETE_DESIGN_DRAFT_SUCCESS: {
      return {
        ...state,
        isFirstDesignDraftDeleted: true,
        designProject: {
          ...state.designProject,
          designerdraft: [
            ...state.designProject.designerdraft.filter(item => item.id !== action.payload),
          ],
        },
      };
    }
    case types.SEND_DRAFT_MESSAGE_SUCCESS: {
      return {
        ...state,
        designProject: {
          ...state.designProject,
          designerdraft: state.designProject.designerdraft.map(item => {
            if (item.id === action.payload.designer_draft_id) {
              return {
                ...item,
                designer_draft_messages: [
                  ...item.designer_draft_messages,
                  action.payload,
                ],
              };
            }

            return item;
          }),
        },
      };
    }
    case types.REMOVE_DESIGN_FILE_SUCCESS: {
      return {
        ...state,
        designProject: {
          ...state.designProject,
          files: state.project.files.filter(item => item.id !== action.payload.id),
        },
      };
    }
    case types.ADD_DESIGN_FILE_SUCCESS: {
      return {
        ...state,
        designProject: {
          ...state.designProject,
          files: [
            ...state.designProject.files,
            action.payload,
          ],
        },
      };
    }
    case types.GET_LIST_OF_DESIGN_PROJECT_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload,
      };
    case types.SEND_DESIGN_PROJECT_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case types.MARK_AS_READ:
      return {
        ...state,
        unreadMessages: 0,
      };
    case types.MARK_MESSAGE_AS_DELETED:
      return {
        ...state,
        messages: state.messages.map((message) => (
          message.id === action.payload.id
            ? { ...message, deleted: action.payload.deleted }
            : message
        )),
      };
    case types.DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: [...action.payload],
      };
    case types.DESIGN_DRAFT_READY_SUCCESS:
      return {
        ...state,
        designProject: {
          ...state.designProject,
          state: action.payload,
        },
        isFeedbackReady: false,
      };
    case types.FEEDBACK_DESIGN_DELIVERED_SUCCESS:
      return {
        ...state,
        designProject: {
          ...state.designProject,
          state: action.payload,
        },
      };
    case types.CLEAR_DESIGN_PROJECT_DATA:
      return initialState;
    case types.UPLOAD_SUBMIT_DESIGN_DRAFT_SUCCESS: {
      return {
        ...state,
        isFirstDesignDraftDeleted: false,
        designProject: {
          ...state.designProject,
          state: PROJECT_STATUS.WAITING_FOR_FEEDBACK,
          designsubmitdrafts: [...state.designProject.designsubmitdrafts, action.payload],
        },
      };
    }
    case types.UPLOAD_DESIGN_FEEDBACK_SUCCESS: {
      const { draftId } = action.payload;
      const draft = state.designProject.designsubmitdrafts.find(item => item.id === draftId);
      const files = [
        ...draft.files,
        action.payload.feedback,
      ];
      return {
        ...state,
        designProject: {
          ...state.designProject,
          state: action.payload.state,
          designsubmitdrafts: [
            ...state.designProject.designsubmitdrafts.filter(item => item.id !== draftId),
            {
              ...draft,
              files,
            },
          ],
        },
      };
    }
    case types.DELETE_SUBMIT_DESIGN_DRAFT_SUCCESS: {
      return {
        ...state,
        isFirstDesignDraftDeleted: true,
        designProject: {
          ...state.designProject,
          state: PROJECT_STATUS.DELIVER_DRAFT,
          designsubmitdrafts: [
            ...state.designProject.designsubmitdrafts.filter(item => item.id !== action.payload),
          ],
        },
      };
    }
    case types.DELETE_SUBMIT_DESIGN_FEEDBACK_SUCCESS: {
      const draft = state.designProject.designsubmitdrafts.find(({ id }) => id === action.payload.draftId);
      const files = draft.files.filter(({ id }) => id !== action.payload.fileId);

      return {
        ...state,
        designProject: {
          ...state.designProject,
          designsubmitdrafts: [
            ...state.designProject.designsubmitdrafts.filter(item => item.id !== action.payload.draftId),
            {
              ...draft,
              files,
            },
          ],
          state: PROJECT_STATUS.WAITING_FOR_FEEDBACK,
        },
      };
    }
    case types.SET_QUANTITY_REQUEST:
      return {
        ...state,
        designProject: {
          ...state.designProject,
          quantity_request: action.payload,
        },
        messages: (state.messages || []).map((message) => (
          message.quantity_request?.id === action.payload.id
            ? ({ ...message, quantity_request: action.payload })
            : message
        )),
      };
    default:
      return state;
  }
}
